<template>
  <div class="container">
    <TitleBarBaseVue />
    <div class="handle_bar">
      <el-row :gutter="20">
        <el-col :xs="10" :sm="10" :md="6" :lg="4" :xl="3">
          <el-input v-model="params.keyWords" @change="changeParams" clearable placeholder="请输入用户ID/昵称/姓名/手机号码"> </el-input>
        </el-col>
        <el-col :xs="7" :sm="7" :md="4" :lg="2" :xl="2">
          <!--@change="handleSearch"-->
          <el-select v-model="params.isRealNameAuth" clearable placeholder="实名认证">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="已实名" :value="0"></el-option>
            <el-option label="未实名" :value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="7" :sm="7" :md="4" :lg="2" :xl="2">
          <!--@change="handleSearch"-->
          <el-select v-model="params.isPhoneAuth" clearable placeholder="手机号码">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="已认证" :value="0"></el-option>
            <el-option label="未认证" :value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <el-date-picker
            v-model="date"
            type="daterange"
            align="right"
            value-format="yyyy-MM-dd"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate"
          >
          </el-date-picker>
          <el-button type="primary" class="margin-left20" @click="handleSearch">搜索</el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data.sync="tableList" border>
      <el-table-column label-class-name="header" prop="userId" label="用户ID" width="80" align="center"></el-table-column>
      <el-table-column label="用户头像" min-width="200" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.profilePic" alt style="width:48px;height:48px;" />
        </template>
      </el-table-column>
      <el-table-column prop="nickName" label="用户昵称" min-width="200" align="center"></el-table-column>
      <el-table-column prop="trueName" label="姓名" min-width="120" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" min-width="120" align="center"></el-table-column>
      <el-table-column prop="receAddress" label="收货地址" width="200" align="center"> </el-table-column>
      <el-table-column prop="becomeGoldenMoney" label="申请金额" min-width="120" align="center">
        <template slot-scope="scope"> {{ scope.row.becomeGoldenMoney || 0 }}元 </template>
      </el-table-column>
      <el-table-column prop="becomeGoldenTime" label="申请时间" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.becomeGoldenTime | dateformat('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column prop="hotelNum" label="酒店数量" align="center"></el-table-column>
      <el-table-column label="售货机" align="center">
        <template slot-scope="scope"> {{ scope.row.useVendingmNum || 0 }}/{{ scope.row.buyVendingmNum || 0 }} </template>
      </el-table-column>
      <el-table-column label="操作" min-width="160" align="center">
        <template slot-scope="scope">
          <el-button @click="handleCancle(scope.row)" size="small" type="danger" plain>取消资格</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageCo">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.pageNo"
        :page-sizes="[1, 2, 5, 10, 20]"
        :page-size="params.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import testImg from '@/assets/images/test.jpg'
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'
import { userGoldenReferList, userGoldenCancel } from '@/api/UserController'
let userType = {
  0: '普通用户',
  1: '金牌推荐官',
  2: 'pc后台管理员',
  3: 'pc超级管理员',
  4: '官方推荐官'
}
export default {
  data() {
    return {
      tableList: [],
      params: {
        keyWords: '',
        isRealNameAuth: '',
        source: '',
        isPhoneAuth: '',
        startTime: '',
        endTime: '',
        type: '',
        pageNo: 1,
        pageSize: 10
      },
      total: 0,

      date: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {
    async handleCancle(row) {
      await this.$repeatedConfirm('是否确认取消资格操作?')
      let [err, res] = await userGoldenCancel({ userId: row.userId })
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '取消失败')
      }
      console.log(res)
      this.$message.success(res.msg || '取消成功')
      this.getUserList()
    },
    changeParams(v) {
      !v && this.handleSearch()
    },
    changeDate(v) {
      if (v) {
        this.params.startTime = v[0]
        this.params.endTime = v[1]
      } else {
        this.params.startTime = ''
        this.params.endTime = ''
        this.handleSearch()
      }
    },
    handleSearch() {
      console.log(this.params)
      this.getUserList()
    },
    async getUserList() {
      let [err, res] = await userGoldenReferList(this.params)
      if (err) {
        console.log(err)
        return this.$message.error(err.msg || '获取用户列表失败')
      }
      console.log(res)
      //this.$message.success(res.msg || '获取用户列表成功')
      this.tableList = res.data.records
      this.total = res.data.total
    },
    // 监听 pageSize 发生改变
    handleSizeChange(newSize) {
      this.params.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 发生改变
    handleCurrentChange(newPage) {
      this.params.pageNo = newPage
      this.getUserList()
    }
  },
  filters: {
    filterUserType(v) {
      return userType[v]
    }
  },
  components: {
    TitleBarBaseVue
  }
}
</script>
